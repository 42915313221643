import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { DynamicImage, Text } from 'components';
import { tablet, useQuery } from 'styles/breakpoints';
import { AppState } from 'state/types';
import { getLocalisedProduct } from 'utils/localization';
import { Product } from 'types/product';

//@ts-ignore
import DownloadIcon from 'assets/icons/mg/download.svg';

interface ProductData {
  [key: string]: {
    downloadUrl: string;
    image: string;
    title: string;
  };
}

interface OrderSummaryProps {
  title: string;
  productData: ProductData;
  bundlePhoto: { [key: string]: number };
  upsellsKeys: string[];
}

interface AppSub {
  title: string;
  currency: string;
  price: string;
}

interface DownloadLink {
  title: string;
  url: string;
}

const Container = styled.div`
  max-width: 71.25rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.5rem 0rem 5.5rem;
  gap: 2rem;

  @media ${tablet} {
    padding: 2rem 1rem;
    gap: 1.5rem;
  }
`;

const Heading = styled(Text)`
  font-size: 2.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 115%;
  text-align: center;

  @media ${tablet} {
    font-size: 1.875rem;
  }
`;

const SummaryContainer = styled.div`
  width: 100%;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
`;

const OrderItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 2rem;
  @media ${tablet} {
    padding-bottom: 1.5rem;
  }
`;

const TotalItemContainer = styled(OrderItemContainer)`
  border-top: 1px solid #dadada;
  padding-top: 1.5rem;

  @media ${tablet} {
    padding-top: 0.5rem;
  }

  p {
    color: ${({ theme }) => theme.colors.black};
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;

    @media ${tablet} {
      font-size: 1.25rem;
    }
  }
`;

const SummaryProducts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;

  @media ${tablet} {
    gap: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

const ProductContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const ProductContentContainer = styled.div`
  width: 100%;
`;

const ProductTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.25rem;
`;

const ProductTitle = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem;

  @media ${tablet} {
    font-size: 1rem;
  }
`;

const ProductPrice = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-family: 'Fustat', sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem;
  text-align: center;

  @media ${tablet} {
    font-size: 1rem;
  }
`;

const ProductImage = styled(DynamicImage)`
  align-self: flex-start;
  max-width: 3.5335rem;
  max-height: 5rem;

  @media ${tablet} {
    max-width: 2.2rem;
    max-height: 3.1rem;
  }
`;

const ProductDownloadLink = styled.a`
  display: flex;
  align-items: center;
  color: #498d27 !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  text-decoration: underline !important;
  text-underline-offset: 2px;

  &:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }

  @media ${tablet} {
    font-size: 0.875rem;
  }
`;

const ProductDownloadLinkSpacer = styled.div`
  height: 21px;
`;

const ProductImageSpacer = styled.div`
  flex-shrink: 0;
  width: 3.5335rem;

  @media ${tablet} {
    width: 2.2rem;
  }
`;

const SubContainer = styled.div`
  display: flex;
  gap: 0.5rem;

  p {
    color: ${({ theme }) => theme.colors.black};
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.125rem !important;

    span {
      font-style: italic;
    }

    @media ${tablet} {
      font-size: 1rem;
    }
  }
`;

interface ProductComponentPrice {
  title: string;
  price: string;
  currency: string;
  downloadLinks?: DownloadLink[];
  image?: string;
  appSub?: AppSub;
  isBundle?: boolean;
}

const ProductComponent: FC<ProductComponentPrice> = ({
  title,
  price,
  currency,
  downloadLinks,
  image,
  appSub,
}) => (
  <>
    <ProductContainer>
      {image ? (
        <ProductImage src={image} alt={title} />
      ) : (
        <ProductImageSpacer />
      )}
      <ProductContentContainer>
        <ProductTitleContainer>
          <ProductTitle>{title}</ProductTitle>
          <ProductPrice>
            {currency}
            {price}
          </ProductPrice>
        </ProductTitleContainer>
        {(!downloadLinks || downloadLinks.length === 0) && (
          <ProductDownloadLinkSpacer />
        )}
        {downloadLinks && downloadLinks.length === 1 && (
          <ProductDownloadLink href={downloadLinks[0].url} target="_blank">
            <DownloadIcon />
            {downloadLinks[0].title}
          </ProductDownloadLink>
        )}
        {downloadLinks &&
          downloadLinks.length > 1 &&
          downloadLinks.map((link, index) => (
            <ProductDownloadLink
              key={`${link.title}-link-${index}`}
              href={link.url}
              target="_blank"
            >
              <DownloadIcon />
              {link.title}
            </ProductDownloadLink>
          ))}
      </ProductContentContainer>
    </ProductContainer>
    {appSub && (
      <SubContainer>
        <ProductImageSpacer />
        <ProductTitleContainer>
          <p>
            <span>{appSub.title.replace('Moongrade ', '')}</span>
          </p>
          <p>
            {appSub.currency}
            {appSub.price}
          </p>
        </ProductTitleContainer>
      </SubContainer>
    )}
  </>
);

const OrderSummary: FC<OrderSummaryProps> = ({
  title,
  productData,
  bundlePhoto,
  upsellsKeys,
}) => {
  const { isTablet } = useQuery();
  const { selected_plan, upsell_products } = useSelector(
    (state: AppState) => state.user,
  );

  let appSub: AppSub | undefined;

  const selectedProduct = getLocalisedProduct(selected_plan);
  const localisedUpsellProducts = upsell_products.map(p =>
    getLocalisedProduct(p),
  );

  const boughtUpsell = upsell_products.map(item => item.key);

  const sortedUpsells = upsellsKeys.filter(key => !boughtUpsell.includes(key));

  const getBundlePhoto = () => {
    let result = '';

    sortedUpsells?.forEach(str => {
      if (bundlePhoto[str] !== undefined) {
        result += bundlePhoto[str].toString();
      }
    });

    return result;
  };

  const getBundleDownloadLinks = () => {
    const result: DownloadLink[] = [];

    sortedUpsells?.forEach(str => {
      if (productData[str] !== undefined) {
        result.push({
          title: productData[str].title,
          url: productData[str].downloadUrl,
        });
      }
    });

    return result;
  };

  const renderUpsellProducts = (upsell_product: Product, index: number) => {
    const upsellProduct = getLocalisedProduct(upsell_product);

    if (!upsellProduct) {
      return;
    }

    if (upsellProduct.key.includes('sub')) {
      const newAppSub: AppSub = {
        title: upsellProduct.name,
        currency: upsellProduct.currency,
        price: upsellProduct.finalPrice,
      };
      appSub = newAppSub;
      return;
    }

    let imageUrl = '';
    let downloadUrls: DownloadLink[] = [];

    if (
      upsellProduct.key.includes('pdf') &&
      !upsellProduct.key.includes('bundle')
    ) {
      imageUrl = productData[upsellProduct.key]?.image;
      const downloadLink: DownloadLink = {
        url: productData[upsellProduct.key]?.downloadUrl,
        title: 'Download',
      };

      downloadUrls.push(downloadLink);
    }

    if (upsellProduct.key.includes('bundle')) {
      imageUrl = `upsell/wealth_and_wisdom_bundle/en/bundle-${getBundlePhoto()}.png`;
      downloadUrls = [...getBundleDownloadLinks()];
    }

    return (
      <ProductComponent
        key={`product-${index}`}
        title={upsellProduct.name}
        price={upsellProduct.finalPrice}
        currency={upsellProduct.currency}
        image={imageUrl}
        downloadLinks={downloadUrls}
      />
    );
  };

  const upsellsPrice = localisedUpsellProducts.reduce(
    (sum, p) => sum + parseFloat(p?.finalPrice || '0'),
    0,
  );

  const totalPrice = (
    parseFloat(selectedProduct.finalPrice) + parseFloat(upsellsPrice)
  ).toFixed(2);
  const totalPriceTrial = (
    parseFloat(selectedProduct?.trialAmount) + parseFloat(upsellsPrice)
  ).toFixed(2);

  return (
    <Container>
      <Heading type={isTablet ? 'h2600' : 'h1'}>{title}</Heading>
      <SummaryContainer>
        <SummaryProducts>
          {upsell_products.map(renderUpsellProducts)}
          {selectedProduct && (
            <ProductComponent
              title="Moongrade app"
              price={
                selectedProduct.hasTrials
                  ? selectedProduct.trialAmount
                  : selectedProduct.finalPrice
              }
              currency={selectedProduct?.currency}
              image={productData.app.image}
              downloadLinks={[
                { url: productData.app.downloadUrl, title: 'Download' },
              ]}
              appSub={appSub}
            />
          )}
        </SummaryProducts>
        <TotalItemContainer>
          <p>Total</p>
          <p>
            {selectedProduct.currency}
            {selectedProduct?.hasTrials ? totalPriceTrial : totalPrice}
          </p>
        </TotalItemContainer>
      </SummaryContainer>
    </Container>
  );
};

export default OrderSummary;
